<template>
  <div class="verify">
    <uverify_third />
  </div>
</template>

<script>
// @ is an alias to /src
import uverify_third from "@/components/uregister_third/verify.vue";

export default {
  name: "verify_third",
  components: {
    uverify_third
  }
};
</script>